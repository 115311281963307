$primary: #d81921; /* MAIN COLOR */
$secondary: #f69679; /* SECONDARY COLOR */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa; 
$border-radius: 0px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;

@import "https://fonts.googleapis.com/css?family=Gloria+Hallelujah|Josefin+Slab:400,700";

h1,h2 {
  font-family: 'Gloria Hallelujah', sans-serif;
  line-height: 2em;
}

.flash {
	display:none;
}

nav {
	z-index: 1000;
}

.navbar {
	margin-bottom: 0px;

	.navbar-nav {
		margin-top: 17px;
    padding-bottom: 13px;
		@media (max-width: 767px) {
			margin-top: 0px;
		}
	    > li > a {
			text-align: center;
			height: 80px;
			font-size: 17px;
			display: flex;
			align-items: center;


			@media (max-width: 767px) {
				
			    height: 20px;
			    font-size: 14px;
			    display: inline-block;
			}

		    &:hover, &:focus {
		    	color: $primary;
		    }
		    
		}
	}
}

.navbar-right {
	margin-top: 0px;
}
 
.navbar-toggle {
    margin: 30px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;
	
	@media (max-width: 388px) {
		margin-top: 14px;
	}
}

/** LOGIN FORM **/

@mixin btn1 {
	text-align: center;
	margin: 0 auto;
	border: 1px solid $primary;
	border-radius: $border-radius;
	background: $primary;
	color: $wht;
	padding: 0.5em 2em;

	&:hover {
		background: lighten($primary,10%);
		color: lighten($wht, 10%);
		box-shadow: 0px 0px 3px lighten($blk,10%);
	}
}

@mixin btn2 {
	@include btn1;
	background: darken($wht, 5%);
	color: $primary;
	border: none;

	&:hover {
		background: $wht;
		color: $primary;
		border: none;
	}
}

.modal-dialog {
	max-width: 300px;
	text-align: center;
	margin: 6em auto;

	.close {display: none;}

	.modal-content {
		color: $primary;
		
		h2 {
			text-align:center;
		}
	}

	input {
		border: none;
		border-bottom: 1px solid darken($primary,20%);
		text-align: center;
	}
	
	button {
		background: transparent;
		color: $wht;
		display: block;
		border:none;
		padding: 1em 2em;
		margin: 0 auto;

		&:hover {
			background: transparent;
			color: #fff;
			box-shadow: none;
			text-shadow: 0px 0px 3px $blk;
			border:none;
		}
	}
	
	input[type="submit"] {
		@include btn1;
		display: block;
		width: 75%;	
	}
}

.modal-header, .modal-footer {
	background: $primary;
	color: $wht;
}

.modal-header {
	border-radius: 5px 5px 0 0;
}

.modal-footer {
	border-radius: 0 0 5px 5px;
}

input#username {
	margin-bottom: 20px;
}

/** END LOGIN FORM **/

footer {
	padding: 50px 0px 20px;
	background: rgb(216, 25, 33);
	color: $footerLinks;

	a {
		color: $footerLinks;

		&:hover {
			color: lighten($footerLinks, 10%);
		}
	}
}

input#username, input#password {
	width: 100%;
	font-size: 16px;
}

@media (max-width: 1024) {
	input#username, input#password {
  		font-size: 16px;
	}
}

select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}

.section-heading {
    font-size: 200%;
    font-weight: normal;
}

.text-center {
  text-align: center;
}
.text-space {
	line-height: 2.5em;

      @media(max-width: 991px) {
          line-height: 1.5em;
    }  
}

.text-min-space {
	line-height: 1.5em;
	margin: 0 -10px 0 -10px;
}

.text-shadow {
  text-shadow: 2px 2px 2px rgb(0, 0, 0);
    @media (max-width: 450px) {
		 font-size: 20px;
	}
}

.text-red-shadow {
  text-shadow: 0px 0px 4px #FF0000;
  @media (max-width: 450px) {
		 font-size: 20px;
	}
}

.btn {
  background: rgb(216, 25, 33);
  font-size: 15px;
  padding: 1em 3em;
  color: #fafafa;
}

.btn:hover {
  background: #f46a6a;
  color: #fff;
}

.marTop {
margin-top: 4em;
}

.blkTxt {
  color: #fff;
}

.banner_txt {
  font-family:"Gloria Hallelujah",sans-serif;
  line-height: 2em;
}

.section-a-txt {
	font-size: 18px;
    line-height: 1.5em;
}

.content-section-a {
    padding: 50px 0;
    background-color: #f8f8f8;
}

.content-section-b {
	background: url(../img/couple_with_map.jpg);
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-size: cover;
	background-color: #fff;
 	padding: 20px 0;
    background-position: 80% 20%; 
    color: #fff;

    @media(max-width: 1280px)  {
    	background-attachment:scroll;
    }


    @media (max-device-width:1280px) and (orientation: landscape) {
    	background-position: center top;
    	background-attachment:scroll;
    }

    @media(max-width: 670px) {
    	background-position: 65% 20%;
    	background-attachment:scroll;
    }

    @media (max-width: 670px) and (orientation: landscape){
    	background-attachment:scroll;
    	background-position: 70% 20%;
  	}
}

.content-section-c {
    padding: 50px 0;
    background-color: #f8f8f8;
}

.content-section-d {
    background-color: #f8f8f8;
}

.content-section-e {
    padding: 100px 0px;
    color: #fff;
    background: url(../img/couple_in_paris.jpg);
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0px 0px;
    background-attachment:scroll;
   
    // @media(min-width: 1280px)  {
    // 	background-position: 0em 0em;
    // 	background-attachment:scroll;

    // @media(max-width: 1280px)  {
    // 	background-position: 0em 0em;
    // 	background-attachment:scroll;
 

    @media (min-device-width:1280px) and (orientation: landscape) {
    	background-position: center top;
    	// background-attachment:scroll;
    }
 

    // 	background-position: -32em 27em;    // @media(max-width: 1280px)  {
    // 	background-position: center top;
   	// }

    // @media(max-width: 991px) {
    // 	background-position: center center;
    // }

    // }

    // @media (max-width: 767px) {
    // 	background-position: 40%  21em;
    // }

    @media (max-device-width:767px) and (orientation: landscape) {
    	background-position: 0em 0em;
    	    	
    }

    @media(max-width: 670px) {
    	// background-position: 0em 0em;
    	background-position: center center;
    	
    }

    @media (max-width:660px) and (orientation: portrait) {
    	background-position: center center;
    }

    @media (max-width: 568px) and (orientation: landscape) {
      background-position: 42% 50%;

    }

    @media (max-width: 320px) {
      background-position: 42% 50%;
    }
	

}

/** IMG SLIDE DOWN FOR CAPTION EFFECT **/

.demo-2 {
    position:relative;
    width: 100%;
    padding-bottom: 55%;
    overflow:hidden;
    margin-bottom: 0;
    text-align: center;
    background-color: $primary; 

		li { list-style: none; 
		}
}

.demo-2 p,.demo-2 h2 {
    color:#fff;
    padding:10px;
    left:-20px;
    top:20px;
    position:relative
}

.demo-2 h2 {
    font-size: 22px;
    line-height:24px;
    margin-top: 10px;
    font-family:'Josefin Slab', sans-serif;
    font-style: italic;
    font-weight: bold;

    @media (max-width: 1400px) {
    	line-height: 40px;
    	font-size: 18px;
    	margin-top: 1px;
    }
}

.effect img {
    position:absolute;
    left:0;
    bottom: 0px;
    cursor: pointer;
    width: 100%;
    -webkit-transition:bottom .3s ease-in-out;
    -moz-transition:bottom .3s ease-in-out;
    -o-transition:bottom .3s ease-in-out;
    transition:bottom .3s ease-in-out;
}

.effect img.top:hover {
    bottom: -45%;
    padding-top:100px;

    @media (min-width: 1500px) and (max-width: 1800px) {
		bottom: -50%;
    }

    @media (max-width: 1499px) {
    	bottom: -45%;
    }

    @media (max-width: 1005px) {
    	bottom: -35%;
    }
}
	
.noPad {
	padding: 0;
	margin: 0;
}

/** END IMG SLIDE DOWN FOR CAPTION EFFECT **/

.logo {
 max-width: 250px;
 width: 100%;
 @media (max-width:960px) {
  max-width: 300px;
 }
 @media (max-width:767px) {
  margin-top: 7px;
  margin-left: 5px;
  max-width: 175px;
 }
}

@media (max-width: 767px) and (orientation: landscape){
  .navbar-header {
    width: 100%;
  }
  
  .navbar-toggle {
   margin-top: 0.5em;
   margin-bottom: 0.5em;
  }
}

@media (max-width: 670px) and (orientation: landscape){
  h1 {
  font-family: 'Gloria Hallelujah', sans-serif;
  line-height: 1.5em;
  font-size: 2em;
	}
}